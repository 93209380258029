div.hexagon {
  visibility: hidden;
  width: 70px;
  padding: 0 0 60px;
  position: relative;
  overflow: hidden;
  transform: skewX(30deg);
}

div.hexagon > div {
  height: 100%;
  -webkit-transform: skewY(-50deg);
  -moz-transform: skewY(-50deg);
  -ms-transform: skewY(-50deg);
  -o-transform: skewY(-50deg);
  visibility: hidden;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  transform: skewX(-50deg);
}

div.hexagon > div > button {
  height: 100%;
  -webkit-transform: skewY(20deg);
  -moz-transform: skewY(20deg);
  -ms-transform: skewY(20deg);
  -o-transform: skewY(20deg);
  visibility: visible;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  transform: skewX(30deg);
}

/*# sourceMappingURL=index.3487af36.css.map */
