div.hexagon {
  overflow: hidden;
  padding: 0 0 60px 0;
  position: relative;
  -o-transform: skewX(30deg);
  -moz-transform: skewX(30deg);
  -webkit-transform: skewX(30deg);
  -ms-transform: skewX(30deg);
  transform: skewX(30deg);
  visibility: hidden;
  width: 70px;
}

div.hexagon>div {
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  -o-transform: skewY(-50deg);
  -moz-transform: skewY(-50deg);
  -webkit-transform: skewY(-50deg);
  -ms-transform: skewY(-50deg);
  transform: skewX(-50deg);
  visibility: hidden;
  width: 100%;
}

div.hexagon>div>button {
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  -o-transform: skewY(20deg);
  -moz-transform: skewY(20deg);
  -webkit-transform: skewY(20deg);
  -ms-transform: skewY(20deg);
  top: 0;
  transform: skewX(30deg);
  visibility: visible;
  width: 100%;
}
